<template>
  <section id="products">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValue" placeholder="Search" @keyup.enter="searchProduct" />
          </b-input-group>
        </b-col>
        <b-col xl="3" md="3">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
            @click="openGenEmail">
            Generate emails
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showProducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Own products
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-own-products" />
          </h4>
          <b-popover target="popover-own-products" triggers="hover" placement="bottom">
            <span>The 'Own products' section displays a table listing all products from our shop, providing an overview
              of our available inventory. By clicking on a table row, you will get product details along with a table
              showing suppliers that offer the product, including their prices, availability, and delivery times.</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="productsTable.currentPage"
          :items="productsTable.items" :fields="productsTable.fields" :sort-by.sync="productsTable.sortBy"
          :sort-desc.sync="productsTable.sortDesc" :sort-direction="productsTable.sortDirection"
          :filter="productsTable.filter" :filter-included-fields="productsTable.filterOn"
          @sort-changed="sortChangedProducts" @row-clicked="rowClickedProducts" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="productsTable.currentPage" :total-rows="productsTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="product-details-modal" size="xl" title="Product Details" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay :show="showProductDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-card no-body>
          <b-table striped hover responsive class="position-relative" :current-page="productDetailsTable.currentPage"
            :items="productDetailsTable.items" :fields="productDetailsTable.fields"
            :sort-by.sync="productDetailsTable.sortBy" :sort-desc.sync="productDetailsTable.sortDesc"
            :sort-direction="productDetailsTable.sortDirection" :filter="productDetailsTable.filter"
            :filter-included-fields="productDetailsTable.filterOn">
            <template #cell(link_to_product)="data">
              <a :href="data.item.link_to_product" target="_blank">
                {{ data.item.link_to_product }}
              </a>
            </template>
          </b-table>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination v-model="productDetailsTable.currentPage" :total-rows="productDetailsTable.totalRows"
                first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                @change="handleProductDetailsTablePageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
    </b-modal>
    <b-overlay :show="showSupproducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Supplier/competitors products (scraped data)
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-supplier-products" />
          </h4>
          <b-popover target="popover-supplier-products" triggers="hover" placement="bottom">
            <span>The 'Supplier/competitors products (scraped data)' section contains a table listing products with
              SKUs, descriptions, prices, availability, and links to webpages. A button 'Scraped Info' is available to
              open a modal displaying detailed scraped data information.</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
              @click="openScrapedInfo">
              Scraped info
            </b-button>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="supproductsTable.currentPage"
          :items="supproductsTable.items" :fields="supproductsTable.fields" :sort-by.sync="supproductsTable.sortBy"
          :sort-desc.sync="supproductsTable.sortDesc" :sort-direction="supproductsTable.sortDirection"
          :filter="supproductsTable.filter" :filter-included-fields="supproductsTable.filterOn"
          @sort-changed="sortChangedSupproducts">
          <template #cell(link_to_product)="data">
            <a :href="data.item.link_to_product" target="_blank">
              {{ data.item.link_to_product }}
            </a>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="supproductsTable.currentPage" :total-rows="supproductsTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleSupproductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="scraped-info-modal" size="xl" title="Scraped Info" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay :show="showScrapedInfo" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-card no-body>
          <b-table striped hover responsive class="position-relative" :current-page="scrapedInfoTable.currentPage"
            :items="scrapedInfoTable.items" :fields="scrapedInfoTable.fields" :sort-by.sync="scrapedInfoTable.sortBy"
            :sort-desc.sync="scrapedInfoTable.sortDesc" :sort-direction="scrapedInfoTable.sortDirection"
            :filter="scrapedInfoTable.filter" :filter-included-fields="scrapedInfoTable.filterOn" />
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination v-model="scrapedInfoTable.currentPage" :total-rows="scrapedInfoTable.totalRows" first-number
                last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                @change="handleScrapedInfoTablePageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
    </b-modal>
    <b-row>
      <b-col xl="6" md="6">
        <b-overlay :show="showProductsNotInProductRange" spinner-variant="primary" variant="transparent" blur="3px"
          rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Products not in product range
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-notin-products" />
              </h4>
              <b-popover target="popover-notin-products" triggers="hover" placement="bottom">
                <span>The 'Products not in product range' section features a table listing products that are not
                  currently
                  in our webshop's product range.</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative"
              :current-page="productsNotInProductRangeTable.currentPage" :items="productsNotInProductRangeTable.items"
              :fields="productsNotInProductRangeTable.fields" :sort-by.sync="productsNotInProductRangeTable.sortBy"
              :sort-desc.sync="productsNotInProductRangeTable.sortDesc"
              :sort-direction="productsNotInProductRangeTable.sortDirection"
              :filter="productsNotInProductRangeTable.filter"
              :filter-included-fields="productsNotInProductRangeTable.filterOn" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="productsNotInProductRangeTable.currentPage"
                  :total-rows="productsNotInProductRangeTable.totalRows" first-number last-number prev-class="prev-item"
                  next-class="next-item" class="mb-0" @change="handleProductsNotInProductRangeTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col xl="6" md="6">
        <b-overlay :show="showGoogleDriveFiles" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Google drive files with searching product
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-google-drive-files" />
              </h4>
              <b-popover target="popover-google-drive-files" triggers="hover" placement="bottom">
                <span>
                  The 'Google drive files with searching product' section displays files from Google Drive related to
                  the
                  searched product. Clicking on a file will open it in a new window.</span>
              </b-popover>
            </b-card-header>
            <b-badge v-for="(item, index) in googleDriveFiles" :key="index" class="google-drive-files" variant="primary"
              @click="getGoogleDriveFile(item.id)">
              {{ item.name }}
            </b-badge>
          </b-card>
        </b-overlay>
        <b-overlay :show="showSysproducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Supplier/competitors products (uploaded data)
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-supplier-products-uploaded" />
              </h4>
              <b-popover target="popover-supplier-products-uploaded" triggers="hover" placement="bottom">
                <span>
                  The 'Supplier/competitors products (uploaded data)' section displays a table of supplier and
                  competitor
                  products that have been uploaded to the system via an Excel file.</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :current-page="sysproductsTable.currentPage"
              :items="sysproductsTable.items" :fields="sysproductsTable.fields" :sort-by.sync="sysproductsTable.sortBy"
              :sort-desc.sync="sysproductsTable.sortDesc" :sort-direction="sysproductsTable.sortDirection"
              :filter="sysproductsTable.filter" :filter-included-fields="sysproductsTable.filterOn" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="sysproductsTable.currentPage" :total-rows="sysproductsTable.totalRows"
                  first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                  @change="handleSysproductsTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal ref="gen-email-modal" size="xl" title="Generate emails" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-row>
        <b-col xl="4" md="4">
          <b-overlay :show="showManufacturers" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
            <b-card title="Step 1. Select Manufacturer">
              <v-select v-model="selectedManufacturer" :options="allManufacturers" @input="changeManufacturer" />
            </b-card>
          </b-overlay>
        </b-col>
        <b-col xl="8" md="8">
          <b-overlay :show="showSuppliers" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
            <b-card title="Step 2. Select suppliers">
              <b-table v-if="showSuppliersTable" ref="suppliersTable" striped hover responsive selectable
                class="position-relative" :current-page="supplierByManufacturerTable.currentPage"
                :items="supplierByManufacturerTable.items" :fields="supplierByManufacturerTable.fields"
                :sort-by.sync="supplierByManufacturerTable.sortBy"
                :sort-desc.sync="supplierByManufacturerTable.sortDesc"
                :sort-direction="supplierByManufacturerTable.sortDirection" @row-selected="onSupplierSelected">
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
              </b-table>
              <b-button v-if="showSuppliersTable" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                class="btn-block" @click="openGenerateEmail">
                Step 3. Generate emails
              </b-button>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="generate-email-modal" size="xl" title="Generate emails" cancel-title="Close"
      cancel-variant="outline-secondary" ok-title="Send Emails" :no-close-on-backdrop="true" @ok="sendEmails">
      <b-overlay :show="showGenerateEmailModal" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <div v-for="(item, index) in emailTemplates" :key="index">
          <h3>Pre-generated e-mail [{{ item.language }}]</h3>
          <b-form-group label="Subject" label-cols-md="4">
            <b-form-input v-model="item.subject" :value="item.subject" />
          </b-form-group>
          <b-form-group label="Body" label-cols-md="4">
            <b-form-textarea v-model="item.body" rows="25" :value="item.body" />
          </b-form-group>
          <hr>
        </div>
        <h3>Select attachements</h3>
        <b-form-file v-model="files" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
          multiple />
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
  BButton,
  BModal,
  BBadge,
  BPopover,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import axios from 'axios';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BInputGroup,
    BInputGroupPrepend,
    BOverlay,
    BButton,
    BModal,
    BBadge,
    BPopover,
    vSelect,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showProducts: true,
      showProductDetails: true,
      showSupproducts: true,
      showSysproducts: true,
      showProductsNotInProductRange: true,
      showScrapedInfo: true,
      showManufacturers: true,
      showGoogleDriveFiles: false,
      showSuppliers: false,
      showSuppliersTable: false,
      showGenerateEmailModal: true,
      searchValue: '',
      selectedManufacturer: '',
      selectedProductID: '',
      googleDriveFiles: [],
      allManufacturers: [],
      selectedSuppliers: [],
      emailTemplates: [],
      files: [],
      queryParamsProducts: {},
      queryParamsProductDetails: {},
      queryParamsSupproducts: {},
      queryParamsSysProducts: {},
      queryParamsProductsNotInProductRange: {},
      queryParamsScrapedInfo: {},
      queryParamsGoogleDriveFiles: {},
      productsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'pprice',
            label: 'purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sprice',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'supstock_qty', label: 'supplier stock qty', sortable: true },
          {
            key: 'delivery_time',
            label: 'delivery time',
            sortable: true,
            formatter: val => `${val} days`,
          },
          { key: 'manufacturer', label: 'manufacturer', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      productDetailsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'price_brutto',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'supplier_sku', label: 'supplier sku', sortable: true },
          { key: 'supplier', label: 'supplier', sortable: true },
          {
            key: 'price_brutto',
            label: 'price brutto',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'price_netto',
            label: 'price netto',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'qty', label: 'qty', sortable: true },
          {
            key: 'delivery_time',
            label: 'delivery time',
            sortable: true,
            formatter: val => `${val} days`,
          },
          { key: 'link_to_product', label: 'link', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      supproductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'supplier_sku', label: 'supplier sku', sortable: true },
          { key: 'manufacturer_code', label: 'manufacturer code', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'price',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'qty', label: 'qty', sortable: true },
          { key: 'avail', label: 'avail', sortable: true },
          { key: 'supplier', label: 'supplier name', sortable: true },
          {
            key: 'delivery_time',
            label: 'delivery time',
            sortable: true,
            formatter: val => `${val} days`,
          },
          { key: 'manufacturer', label: 'manufacturer', sortable: true },
          { key: 'in_jtl', label: 'in jtl', sortable: true },
          { key: 'link_to_product', label: 'link', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      sysproductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'price',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'supplier', label: 'supplier name', sortable: true },
          { key: 'manufacturer', label: 'manufacturer', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      productsNotInProductRangeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'total_qty',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'product_name', label: 'name', sortable: true },
          { key: 'offers_count', label: 'offers count', sortable: true },
          { key: 'total_qty', label: 'total qty', sortable: true },
          { key: 'customer_name', label: 'customer name', sortable: true },
          { key: 'manufacturer', label: 'manufacturer', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      scrapedInfoTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'upd_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'vendor_name', label: 'vendor name', sortable: true },
          { key: 'scraped_items', label: 'scraped items', sortable: true },
          { key: 'updated_items', label: 'updated items', sortable: true },
          {
            key: 'upd_date',
            label: 'last update',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      supplierByManufacturerTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'total_amount',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'selected', label: 'selected' },
          { key: 'supplier', label: 'supplier', sortable: true },
          { key: 'total_amount', label: 'total amount', sortable: true },
          { key: 'email', label: 'email', sortable: true },
          { key: 'language', label: 'language', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    try {
      await this.getProducts();
      await this.getSupproducts();
      await this.getSysproducts();
      await this.getProductsNotInProductRange();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    onSupplierSelected(items) {
      this.selectedSuppliers = items;
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getProducts() {
      this.showProducts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products/`, this.queryParamsProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productsTable.items = results;
          this.productsTable.totalRows = results[0].count * 2;
        }
        else {
          this.productsTable.items = [];
          this.productsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProducts = false;
      }
    },
    async getProductDetails(id) {
      this.showProductDetails = true;
      this.selectedProductID = id;
      this.queryParamsProductDetails.id = id;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/product-details/`, this.queryParamsProductDetails);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productDetailsTable.items = results;
          this.productDetailsTable.totalRows = results[0].count * 2;
        }
        else {
          this.productDetailsTable.items = [];
          this.productDetailsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductDetails = false;
      }
    },
    async getSupproducts() {
      this.showSupproducts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/supproducts/`, this.queryParamsSupproducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.supproductsTable.items = results;
          this.supproductsTable.totalRows = results[0].count * 2;
        }
        else {
          this.supproductsTable.items = [];
          this.supproductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showSupproducts = false;
      }
    },
    async getSysproducts() {
      this.showSysproducts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/sys-products/`, this.queryParamsSysProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.sysproductsTable.items = results;
          this.sysproductsTable.totalRows = response.data.count * 2;
        }
        else {
          this.sysproductsTable.items = [];
          this.sysproductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showSysproducts = false;
      }
    },
    async getProductsNotInProductRange() {
      this.showProductsNotInProductRange = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-not-in-product-range/`, this.queryParamsProductsNotInProductRange);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productsNotInProductRangeTable.items = results;
          this.productsNotInProductRangeTable.totalRows = results[0].count * 2;
        }
        else {
          this.productsNotInProductRangeTable.items = [];
          this.productsNotInProductRangeTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductsNotInProductRange = false;
      }
    },
    async getGoogleDriveFiles() {
      this.showGoogleDriveFiles = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/google-drive-file-search/`, this.queryParamsGoogleDriveFiles);
        this.googleDriveFiles = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showGoogleDriveFiles = false;
      }
    },
    async getGoogleDriveFile(googleDriveFileId) {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/google-drive-file-display/`, { fileId: googleDriveFileId });
        window.open(response.data.results, '_blank');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getManufacturers() {
      this.showManufacturers = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-manufacturers/`, {});
        this.allManufacturers = response.data.results.map(item => item.value);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showManufacturers = false;
      }
    },
    async getScrapedInfo() {
      this.showScrapedInfo = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/scraped-info/`, this.queryParamsScrapedInfo);
        this.scrapedInfoTable.items = response.data.results;
        this.scrapedInfoTable.totalRows = 21;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showScrapedInfo = false;
      }
    },
    async getEmailTemplates() {
      this.showGenerateEmailModal = true;
      const selectedLanguages = this.selectedSuppliers.map(item => item.language).filter((v, i, a) => a.indexOf(v) === i);
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/email-templates/`, { languages: selectedLanguages });
        const emailTemplates = response.data.results;
        emailTemplates.forEach(item => {
          if (localStorage.getItem('firstName') && localStorage.getItem('lastName')) {
            item.body = item.body.replace('Jose Luis Rodriguez',
              `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`)
          }
        });
        this.emailTemplates = emailTemplates;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showGenerateEmailModal = false;
      }
    },
    async sortChangedProducts(value) {
      if (value.sortDesc === true) {
        this.queryParamsProducts.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsProducts.ordering = value.sortBy;
      }
      await this.getProducts();
    },
    async sortChangedSupproducts(value) {
      if (value.sortDesc === true) {
        this.queryParamsSupproducts.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsSupproducts.ordering = value.sortBy;
      }
      await this.getSupproducts();
    },
    async rowClickedProducts(row) {
      this.$refs['product-details-modal'].show();
      await this.getProductDetails(row.id);
    },
    async searchProduct() {
      let searchValue = this.searchValue.toLowerCase().replace(/-/g, "").replace(/ /g, "");

      this.queryParamsProducts.page = 1;
      this.queryParamsProducts.search = '%' + searchValue + '%';
      this.queryParamsSupproducts.page = 1;
      this.queryParamsSupproducts.search = '%' + searchValue + '%';
      this.queryParamsSysProducts.page = 1;
      this.queryParamsSysProducts.search = '%' + searchValue + '%';
      this.queryParamsProductsNotInProductRange.page = 1;
      this.queryParamsProductsNotInProductRange.search = '%' + searchValue + '%';
      this.queryParamsGoogleDriveFiles.search = '%' + searchValue + '%';

      await this.getProducts();
      await this.getSupproducts();
      await this.getSysproducts();
      await this.getProductsNotInProductRange();
      await this.getGoogleDriveFiles();
    },
    async handleProductsTablePageChange(value) {
      this.queryParamsProducts.page = value;
      await this.getProducts();
    },
    async handleProductDetailsTablePageChange(value) {
      this.queryParamsProductDetails.page = value;
      await this.getProductDetails(this.selectedProductID);
    },
    async handleSupproductsTablePageChange(value) {
      this.queryParamsSupproducts.page = value;
      await this.getSupproducts();
    },
    async handleSysproductsTablePageChange(value) {
      this.queryParamsSysProducts.page = value;
      await this.getSysproducts();
    },
    async handleProductsNotInProductRangeTablePageChange(value) {
      this.queryParamsProductsNotInProductRange.page = value;
      await this.getProductsNotInProductRange();
    },
    async handleScrapedInfoTablePageChange(value) {
      this.queryParamsScrapedInfo.page = value;
      await this.getScrapedInfo();
    },
    async openScrapedInfo() {
      this.$refs['scraped-info-modal'].show();
      await this.getScrapedInfo();
    },
    async openGenEmail() {
      this.$refs['gen-email-modal'].show();
      await this.getManufacturers();
    },
    async openGenerateEmail() {
      this.$refs['generate-email-modal'].show();
      await this.getEmailTemplates();
    },
    async changeManufacturer() {
      this.showSuppliers = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/suppliers-by-manufacturer/`, { manufacturer: this.selectedManufacturer });
        this.supplierByManufacturerTable.items = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showSuppliers = false;
        this.showSuppliersTable = true;
      }
    },
    async sendEmails() {
      this.showGenerateEmailModal = true;

      const formData = new FormData();
      this.selectedSuppliers.forEach(item => {
        formData.append('emails', item.email);
        formData.append('languages', item.language);
      })

      this.emailTemplates.forEach(item => {
        formData.append('subjects', item.subject);
        formData.append('bodies', item.body);
      })

      for (var i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        formData.append('files', file);
      }

      await axios
        .post(`${process.env.VUE_APP_ROOT_API}/send-emails-to-suppliers/`, formData, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          if (response.data.success) {
            this.showToast('success', response.data.message);
          } else {
            ;
            this.showToast('danger', response.data.message)
          }
          this.showGenerateEmailModal = false;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' });
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.google-drive-files {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
</style>
